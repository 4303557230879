import * as React from "react"
import { Link } from "gatsby"
import logo from "../images/sean-becker-logo.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from "@fortawesome/free-solid-svg-icons"

const Footer = () => (
  <footer>   
    <div className="footer-wrapper">
      <div className="footer-section">          
        <span className="contact">Get in touch</span>
        <span>410-757-5650</span><br />
        <span>sbecker@seanbeckerlaw.com</span><br />    
        <span><a href="https://www.google.com/maps/place/1362+Cape+St+Claire+Rd,+Annapolis,+MD+21409/@39.0385059,-76.4413469,17z/data=!3m1!4b1!4m5!3m4!1s0x89b802bc1eca2411:0xe22c60aa92d94b30!8m2!3d39.0385059!4d-76.4391582" rel="noreferrer" target="_blank"><FontAwesomeIcon  icon={faMap} aria-label="View Map" /></a> 1362 Cape St. Claire Road<br />Annapolis, MD 21409 </span>   
      </div>
      <div className="footer-section">
        <Link to="/"><img src={logo} alt="Sean Becker Law logo" /></Link>
      </div>  
      
      <div className="footer-section">          
        <span className="contact">Services</span>
        <ul className="nav-foot">             
          <li>
              <Link to="/traffic-violations-n-DWI-DUI">Traffic Violations &amp; DWI/DUI</Link>
          </li>         
          
          <li>
              <Link to="/family-law-n-divorce">Family Law / Divorce &amp; Custody</Link>
          </li>
          <li>
              <Link to="/domestic-violence">Domestic Violence</Link>
          </li>
          <li>
              <Link to="/criminal-law">Criminal Law</Link>
          </li>         
          
          <li>
              <Link to="/wills-n-estates">Wills</Link>
          </li> 
        </ul>       
      </div>
    </div>
    <div className="nav-footer-end text-center">      
      <div className="p-3 pb-3 text-center">
        <span>© {new Date().getFullYear()} Sean Becker Law</span>
      </div>
    </div>     
    
  </footer>
)

export default Footer
