/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "@fontsource/open-sans"
import "@fontsource/ibm-plex-serif"
import "./layout.css"
import "../styles/main.scss"

const Layout = ({ children }) => {
  /*
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
*/
  return (
    <>
      <Header />      
        <main>
          <div className="row g-0 justify-content-center">
            <div className="col-12">
            {children}
            </div>
          </div>
        </main>        
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
