import * as React from "react"
import { Link } from "gatsby"
import logo from "../images/sean-becker-logo.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Header = () => (
  <header>    
    <div className="nav-main">      
      <nav> 
        <ul className="nav-mobile d-md-none">      
          <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle text-end" data-bs-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon icon={faBars} aria-label="Open/Close Menu"  /></button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/meet-attorney-sean-becker">Meet Sean</a></li>
                <li><a className="dropdown-item" href="/contact">Contact</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><h6 className="dropdown-header">Services</h6></li>
                <li><a className="dropdown-item" href="/criminal-law">Criminal Law</a></li>
                <li><a className="dropdown-item" href="/domestic-violence">Domestic Violence</a></li>
                <li><a className="dropdown-item" href="/family-law-n-divorce">Family Law / Divorce &amp; Custody</a></li>
                <li><a className="dropdown-item" href="/traffic-violations-n-DWI-DUI">Traffic Violations &amp; DWI/DUI</a></li>
                <li><a className="dropdown-item" href="/wills-n-estates">Wills &amp; Estates</a></li>
              </ul>              
          </li>
          <li>
              <Link to="/"><img src={logo} alt="Logo" /></Link>
          </li>   
          <li className="text-center">
            <h3><span className="text-secondary">Contact us today!</span><br /><span className="text-custom">410-757-5650</span></h3>
          </li> 
        </ul>        
        <ul className="d-none d-md-flex logo-wrapper nav align-items-center justify-content-between">      
          <li className="text-center">
            <h3><span className="text-secondary">Contact us today!</span><br /><span className="text-custom">410-757-5650</span></h3>
          </li>  
          <li>
              <Link to="/"><img src={logo} alt="Logo" /></Link>
          </li>       
          <li className="nav-item dropdown">
          <button className="nav-link dropdown-toggle text-end" data-bs-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon icon={faBars} aria-label="Open/Close Menu"  /></button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/meet-attorney-sean-becker">Meet Sean</a></li>
                <li><a className="dropdown-item" href="/contact">Contact</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><h6 className="dropdown-header">Services</h6></li>
                <li><a className="dropdown-item" href="/criminal-law">Criminal Law</a></li>
                <li><a className="dropdown-item" href="/domestic-violence">Domestic Violence</a></li>
                <li><a className="dropdown-item" href="/family-law-n-divorce">Family Law / Divorce &amp; Custody</a></li>
                <li><a className="dropdown-item" href="/traffic-violations-n-DWI-DUI">Traffic Violations &amp; DWI/DUI</a></li>
                <li><a className="dropdown-item" href="/wills-n-estates">Wills &amp; Estates</a></li>
              </ul>              
          </li>
        </ul>
      </nav>      
    </div>
  </header>
)

export default Header
